<template>
	<div class="modal-mask">
		<div id="experience-form-modal" class="experience-form-modal">
			<i class="fas fa-times close-modal" @click="$emit('close')"></i>
			<h2>{{ title }} Experience</h2>
			<form action="">
				<div class="input-field">
					<input
						type="text"
						name="job-title"
						id="job-title"
						placeholder="Job title *"
						v-model="experience.designation"
						required>
				</div>
				<div class="input-field">
					<input
						type="text"
						name="company-name"
						id="company-name"
						placeholder="Company name *"
						v-model="experience.companyName"
						required>
				</div>
				<div class="input-field current-job">					
					<input
						@click="currentJob = !currentJob"
						type="checkbox"
						value="1"
						name="current-job"
						id="current-job"
						v-model="currentJob">
					<label for="current-job">
						Present Job?
					</label>
				</div>
				<div class="input-field split">
					<input
						class="input-month"
						type="month"
						name="start-month"
						id="start-month"
						v-model="startDate"
						required>
					<em v-if="!currentJob">-</em>
					<input
						v-if="!currentJob"
						class="input-month"
						type="month"
						name="end-month"
						id="end-month"
						v-model="endDate"
						required>
				</div>
				<div class="input-field">
					<textarea
						name="description"
						id="description"
						placeholder="Description *"
						rows="10"
						v-model="experience.description"
						required></textarea>
				</div>
				<div class="links">
					<div 
						class="input-field split">
						<input
							type="text"
							name="link-text"
							id="link-text"
							v-model="linkText"
							placeholder="Anchor">
						<em>-</em>
						<input
							type="url"
							name="links"
							id="links"
							v-model="linkUrl"
							@keyup.enter="addLink"
							placeholder="Link">
						<button @click.prevent="addLink" class="btn btn-primary add-link">Add link</button>
					</div>
					<div class="split display-link" v-for="(link, index) in experience.links" :key="index">
						<i class="fas fa-link" style="margin-right: 10px"></i>
						<a :href="link.url" target="_blank">{{ link.text }} - {{ link.url }}</a>
						<i class="fas fa-trash-alt delete-exp" @click="deleteLink(index)"></i>
					</div>
				</div>
				<button
					type="submit"
					@click.prevent="$emit('addUpdate', updatePeriod, experience)"
					class="btn btn-primary"
					style="margin-top: 0.75rem">
					{{ title === 'Edit' ? 'Update' : 'Add' }}</button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Modal',
	props: ['item', 'title'],
	data() {
		return {
			experience: {},
			currentJob: this.isCurrentJob(),
			startDate: '',
			endDate: '',
			period: ''
		}
	},
	methods: {
		isCurrentJob() {
			let period = this.item.period;
			if (period !== '') {
				this.startDate = this.convertDate(period.slice(0, period.indexOf('-')-1));
				if (period.slice(-7) === 'Present') {
					this.endDate = 'Present';
					return true
				} else {
					this.endDate = this.convertDate(period.slice(period.indexOf('-')+2));
					return false
				}
			} else {
				return false
			}
		},
		convertDate(a) { // March 2021 -> 2021-03
			a = a.split(' ');
			let month = a[0];
			let year = a[1];
			let date = year + '-';
			switch (month) {
				case 'January':
					date = date + '01';
					break;
				case 'February':
					date = date + '02';
					break;
				case 'March':
					date = date + '03';
					break;
				case 'April':
					date = date + '04';
					break;
				case 'May':
					date = date + '05';
					break;
				case 'June':
					date = date + '06';
					break;
				case 'July':
					date = date + '07';
					break;
				case 'August':
					date = date + '08';
					break;
				case 'September':
					date = date + '09';
					break;
				case 'October':
					date = date + '10';
					break;
				case 'November':
					date = date + '11';
					break;
				case 'December':
					date = date + '12';
					break;
				default:
					break;
			}
			return date;
		},
		dateConvert(type, a) { // 2021-03 -> March 2021
			if (type === 'end' && this.currentJob === true) {
				return 'Present';
			}
			a = a.split('-');
			let year = a[0];
			let month = a[1];
			let date = '';
			switch (month) {
				case '01':
					date = 'January';
					break;
				case '02':
					date = 'February';
					break;
				case '03':
					date = 'March';
					break;
				case '04':
					date = 'April';
					break;
				case '05':
					date = 'May';
					break;
				case '06':
					date = 'June';
					break;
				case '07':
					date = 'July';
					break;
				case '08':
					date = 'August';
					break;
				case '09':
					date = 'September';
					break;
				case '10':
					date = 'October';
					break;
				case '11':
					date = 'November';
					break;
				case '12':
					date = 'December';
					break;
				default:
					break;
			}
			return date + ' ' + year;
		},
		addLink() {
			this.experience.links.push({
				text: this.linkText,
				url: this.linkUrl
			});
			this.linkText = this.linkUrl = '';
		},
		deleteLink(index) {
			this.experience.links.splice(index, 1);
		},
		loadData() {
			this.experience = this.item;
			this.isCurrentJob();
		}
	},
	computed: {
		updatePeriod() {
			return this.dateConvert('start', this.startDate) + ' - ' + this.dateConvert('end', this.endDate);
		},
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style lang="scss" scoped>
.experience-form-modal {
	position: absolute;
	left: 50%;
	top: 50%;
	max-height: 70%;
	width: 70%;
	z-index: 99;
	color: white;
	padding: 3rem;
	border-radius: 1rem;
	transform: translate(-50%, -50%);
	background-image: url(../../assets/bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: overlay;
}

h2 {
	margin: 0;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

input[type=text], input[type=url], input[type=month], textarea {
	border-radius: 8px;
	padding: 8px;
	vertical-align: middle;
	border: none;
	color: black;
	background: #f1f1f1;
	opacity: 0.9;
	width: 90%;
}
input[type=text]:focus, input[type=url]:focus, input[type=month]:focus, textarea:focus {
	background-color: #ddd;
	outline: none;
}
.input-field {
	margin: 0.75rem 0;
}
.split {
	display: flex;
	align-items: center;
	margin: 0 5%;
	grid-gap: 1rem;
	.input-month {
		width: 45%;
	}
	input {
		margin: 0.75rem 0;
	}
}

.fa-trash-alt {
	margin: auto 0;
	color: burlywood;
	background-color: #a90909;
	padding: 0.6rem;
	border-radius: 50%;
	filter: sepia(1);
	transition: all 200ms ease-in-out;
	cursor: pointer;
}
.fa-trash-alt:hover {
	filter: sepia(0)
}
.display-link {
	color: white;
    margin: 0.75rem 5%;
    word-break: break-word;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
	a {
		color: white;
	}
}

.current-job {
	cursor: pointer;
	display: flex;
    align-items: center;
    margin: 0 5%;
	label {
		margin: 0;
		width: 82px;
		display: flex;
		align-items: center;
	}
	input[type=checkbox] {
		height: 1.4rem;
		width: 1.4rem;
		margin-right: 0.6rem;
	}
}

.add-link {
	width: 155px;
}

.close-modal {
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 15px;
	font-size: 2rem;
	transition: all 100ms ease-in-out;
	&:hover {
		transform: scale(1.1);
	}
}

@media screen and (max-width: 620px) {
	.split {
		flex-direction: column;
		grid-gap: 0;

		.input-month {
			width: 100%;
		}
	}
}
</style>