<template>
	<div class="edit-experience-form">
		<div class="action-buttons">
			<button @click="addEditExperience(item, editableExperience.length, 'Add New')" class="btn btn-primary">Add New Experience</button>
			<button @click="updateExperiences" class="btn btn-success">Save instance</button>
		</div>
		<AddEditExperience v-if="showModal" @close="clearModalData" @addUpdate="updateLocalExperiences" :item="item" :title="title"></AddEditExperience>
		<div class="show-experiences">
			<swiper 
				:effect="'coverflow'" 
				:grabCursor="true"
				:centeredSlides="true" 
				:slidesPerView="'auto'" 
				:coverflowEffect='{
					"rotate": 50,
					"stretch": 0,
					"depth": 100,
					"modifier": 1,
					"slideShadows": true
				}' :pagination="true">
				<swiper-slide
					class="card col-sm-12" style="max-width: 25rem;" v-for="(item, index) in editableExperience" :key="index">
					<div class="card-header">
						<div class="action-icons">
							<i class="fas fa-pen-alt edit-exp" @click="addEditExperience(item, index, 'Edit')"></i>
							<i class="fas fa-trash-alt delete-exp" @click="deleteExperience(index)"></i>
						</div>
						<div class="card-subheader">
							<strong class="designation">{{ item.designation }}</strong>
							<p class="card-text">{{ item.period }}</p>    
						</div>
					</div>
					<div class="card-body">
						<h5 class="card-title"><i class="far fa-building" style="margin-right: 10px;"></i>{{ item.companyName }}</h5>
						<p class="card-text">{{ item.description }}</p>
						<div v-if="item.links !== null">
							<div class="card-text" :key="index" v-for="(link, index) in item.links">
								<i class="fas fa-link" style="margin-right: 10px"></i>
								<a :href="link.url"
									target="_blank"
									>{{ link.text }}</a>
								<br>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

// import Swiper core and required modules
import SwiperCore, { Pagination, A11y, EffectCoverflow } from 'swiper';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// install Swiper modules
SwiperCore.use([ Pagination, A11y, EffectCoverflow]);

// Import Swiper styles
import 'swiper/swiper.scss';
require('swiper/components/pagination/pagination.scss');
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';

import AddEditExperience from './AddEditExperience.vue';

export default {
	Name: 'EditExperience',
	components: {
		Swiper,
		SwiperSlide,
		AddEditExperience
	},
	data() {
		return {
			editableExperience: this.$store.state.experiences,
			index: 0,
			showModal: false,
			item: {
				designation: '',
				period: 'Present',
				companyName: '',
				description: '',
				links: []
			},
			title: ''
		}
	},
	methods: {
		...mapActions({
			saveExperiences: 'updateExperiences'
		}),
		updateExperiences() {
			this.saveExperiences(this.editableExperience);
			this.$emit('notification', 'Changes saved locally!');
		},
		addEditExperience(item, index, title) {
			this.index = index;
			this.item = item;
			this.title = title;
			this.showModal = true;
		},
		deleteExperience(index) {
			let designation = this.editableExperience[index].designation;
			this.editableExperience.splice(index, 1);
			this.$emit('notification', '"'+ designation + '" deleted!', 'delete');
		},
		clearModalData() {
			this.showModal = false;
			this.item = {
				designation: '',
				period: 'Present',
				companyName: '',
				description: '',
				links: []
			};
		},
		updateLocalExperiences(period='', experience) {
			this.showModal = false;
			if (this.title === 'Add New') {
				this.editableExperience.push(experience);
			}
			this.editableExperience[this.index].period = period;
			this.item = {
				designation: '',
				period: 'Present',
				companyName: '',
				description: '',
				links: []
			};
			this.title = 'Add New';
			this.newExp();
			this.$emit('notification', 'Experiencess updated!');
		},
		newExp() {
			this.editableExperience.sort((a,b) => {
				a = a.period.split(' - ');
				b = b.period.split(' - ');
				let aStartDate = a[0];
				let aEndDate = a[1];
				let bStartDate = b[0];
				let bEndDate = b[1];
				if (aEndDate == 'Present' && bEndDate == 'Present') {
					return (new Date(bStartDate) - new Date(aStartDate)) > 1 ? 1 : -1;
				} else if (aEndDate == 'Present') {
					return -1;
				} else if (bEndDate == 'Present') {
					return 1;
				} else {
					return (new Date(bStartDate) - new Date(aStartDate)) > 1 ? 1 : -1;
				}
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.edit-experience-form {
	padding: 5rem 0;
}

.designation {
	width: 15rem;
}

.card-header {
	display: flex;
	align-items: baseline;
}

.action-icons {
	display: grid;
	grid-gap: 0.5rem;
	margin-right: 0.9rem;
	font-size: 1.1rem;
	i {
		padding: 0.5rem;
		margin: 0 0.2rem;
		border-radius: 50%; 
		filter: sepia(1);
		transition: all 150ms ease-in-out;
		cursor: pointer;
	}
	i:hover {
		filter: sepia(0);
	}
}
.edit-exp {
	background-color: #f9e73c;
}
.delete-exp {
	color: burlywood;
	background-color: #a90909;
}

.action-buttons {
	display: flex;
	flex-wrap: wrap;
	padding: 0 10vw;
	grid-gap: 1rem;
	margin-bottom: 1rem;
}
</style>